import React from "react"
import { graphql, PageProps } from "gatsby"
import SEO from "../components/seo"
import { Query } from "../../types/graphql-types"
import Layout from "../components/layout"
import PageTitle from "../components/utils/PageTitle"
import "../components/Company/style.css"
import { ja_description } from "../components/const"

const Company: React.FC<PageProps<Query>> = ({ data, location }) => {
  const companyData = data.microcmsCompany
  return (
    <Layout location={location} selectPageName="Company">
      <SEO title="Company" description={ja_description} lang={"ja"} />
      <div className={"company-wrapper"}>
        <div className={"company-title"}>
          {companyData?.title && (
            <PageTitle title={companyData.title} key={companyData.id} />
          )}
        </div>
        <div className={"company-item-wrapper"}>
          <div>
            <div className={"company-name-wrapper"}>
              <p className={"company-name-en"}>D Capital</p>
              <p className={"company-name-ja"}>株式会社</p>
            </div>
            <div className={"company-info-container"}>
              {companyData?.founded_date && (
                <div className={"company-info-item"}>
                  <div>
                    <p className={"company-contents-title"}>設立日</p>
                  </div>
                  <div>
                    <p className={"company-contents-body"}>
                      {companyData.founded_date}
                    </p>
                  </div>
                </div>
              )}
              {companyData?.business_overview && (
                <div className={"company-info-item"}>
                  <div>
                    <p className={"company-contents-title"}>事業概要</p>
                  </div>
                  <div>
                    <p className={"company-contents-body"}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: `${companyData.business_overview}`
                        }}
                      />
                    </p>
                  </div>
                </div>
              )}
              {companyData?.address && (
                <div className={"company-info-item"}>
                  <div>
                    <p className={"company-contents-title"}>所在地</p>
                  </div>
                  <div>
                    <p className={"company-contents-body"}>
                      {companyData.address}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default Company

export const CompanyIndexQuery = graphql`
  query CompanyIndexQuery {
    microcmsCompany {
      address
      business_overview
      founded_date
      title
    }
  }
`
